.management {
  max-width: 720px;
  margin: 0 auto;
  padding: 0;
  background-color: white;

  .filterBox {
    display: flex;
    input {
      font-size: 1.3em;
      padding: 0.6rem 1.3rem;
      height: 60px;
      border: none;
      border-bottom: 2px solid #ccc;
      width: 80%;
    }

    select {
      font-size: 1.3em;
      padding: 0.6rem 1.3rem;
      height: 60px;
      border: none;
      border-bottom: 2px solid #ccc;
      border-left: 1px solid #e3e3e3;
      width: 50%;
      background-color: white;
      @media screen and (min-width: 480px) {
        width: 25%;
      }
    }
  }

  .locations {
    list-style: none;
    margin: 0;
    padding: 0 1em;
    li {
      border-top: 1px solid #cccccc;
      &:first-child {
        border-top: none;
      }
    }
  }
}
