@import url('https://fonts.googleapis.com/css?family=Roboto');

*,
:after,
:before {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

#root {
  display: flex;
  flex-direction: column;
}

#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

a {
  color: #47a0db;
  text-decoration: none;
}

p.error {
  color: #a94442;
  text-align: initial;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.rdtPicker {
  right: 0;
}
