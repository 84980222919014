ul.inner {
  list-style: none;
  margin: 0;
  padding: 0 0 0 0.7rem;
  li.inner {
    margin-top: 1px;

    &_filter {
      background-color: rgba(blue, 0.2);
    }
    .label {
      display: flex;
      align-items: center;
      padding: 0 0.5em;

      .colorMark {
        height: 4px;
        width: 1px;
      }

      input {
        width: auto;
        margin: 0 0.5rem 0 0;
      }

      .field {
        flex: 1;
        cursor: pointer;
        display: block;
        padding: 0.5em 0;
        color: #000;
      }
    }
  }
}
