$standoff-small: 12px;
$standoff-medium: $standoff-small + 2px;
$standoff-big: $standoff-medium + 4px;
$standoff-large: $standoff-big + 6px;

$color-dark-1: rgba(0, 0, 0, 0.25);
$color-dark-2: rgba(0, 0, 0, 0.5);
$color-dark-3: rgba(0, 0, 0, 0.9);
$color-light-1: rgba(255, 255, 255, 0.25);
$color-light-2: rgba(255, 255, 255, 0.5);
$color-light-3: rgba(255, 255, 255, 0.75);
$color-light-4: rgba(255, 255, 255, 0.9);

$bus-logo-size: 64px;

.card {
  position: absolute;
  right: 1.8em;
  top: 4.2em;
  width: 500px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px $color-dark-2;
  z-index: 10;
  max-width: 85%;
  border-radius: 3%;
  overflow: hidden;

  &__header {
    height: auto;
    position: relative;
    padding: 10px 20px 10px;
    display: flex;
    flex-direction: column;

    &__section {
      padding: 0;
      flex: 1;

      .headsign {
        color: $color-light-4;
        font-size: $standoff-big;
        font-weight: 500;
        margin: 1% 0;
      }

      @media screen and (min-width: 480px) {
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 480px) {
      height: 104px;
      flex-direction: row;
      align-items: center;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;
  }

  &__footer {
    padding: 1rem;
    text-align: center;
    opacity: 0.9;
    button {
      color: #ffffff;
      width: 100%;
      font-weight: bold;
    }
  }
}

.bus_logo {
  width: $bus-logo-size;
  height: $bus-logo-size;
  display: none;

  @media screen and (min-width: 480px) {
    display: initial;
  }
}

.row-container {
  display: flex;
  flex-direction: row;
  padding: 1% 2%;

  &:nth-child(1) {
    padding: 2% 2% 1%;
  }

  &:last-child {
    padding: 1% 2% 3%;
  }

  &:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .label-container {
    width: 35%;
  }
}

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.battery-status {
  font-size: $standoff-medium;
  text-align: center;
  display: flex;
  align-items: center;
  color: $color-light-3;
  margin: 1% 0;

  .circle {
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #ccc;
    border-radius: 100%;
    margin-right: 5px;

    &.red {
      background-color: #eb2b2b;
    }

    &.yellow {
      background-color: #dede1f;
    }

    &.green {
      background-color: #3ecb3e;
    }
  }
}

.battery-level {
  font-size: $standoff-medium;
  color: $color-light-3;
  margin: 1% 0;
}
