@import url(https://fonts.googleapis.com/css?family=Roboto);
.Layout_layout__3Mrby {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .Layout_layout__3Mrby .Layout_content__2H50A {
    position: relative;
    flex: 1 1; }

ul.MarkerList_inner__35oTB {
  list-style: none;
  padding: 0 0 0 0.7rem;
  margin: 0 0 0 0.45rem;
  border-left: 2px solid; }
  ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB {
    margin-top: 1px; }
    ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_field__24F1L {
      flex: 1 1;
      display: block;
      position: relative;
      padding: 0.5em;
      color: #000; }
      ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_field__24F1L:hover .MarkerList_indicator__2C9Yp {
        display: inline;
        display: initial; }
      ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_field_filter__3Z7s3 {
        background-color: rgba(0, 0, 255, 0.2); }
    ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_indicator__2C9Yp {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, white 30%, white 100%);
      padding: 3px 3px 3px 8px; }
      ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_indicator__2C9Yp svg {
        width: 15px;
        height: 15px;
        fill: #aaa; }
      ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_indicator__2C9Yp:hover {
        background: white;
        padding: 3px 3px 3px 8px; }
        ul.MarkerList_inner__35oTB li.MarkerList_inner__35oTB .MarkerList_indicator__2C9Yp:hover svg {
          fill: #000; }

.MarkerList_visible__3YOG0 {
  opacity: 0.5; }

.ToggleRoute_container__ufgqw {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0.2rem 0; }
  .ToggleRoute_container__ufgqw.ToggleRoute_visible__3_EMp {
    opacity: 0.5; }
  .ToggleRoute_container__ufgqw .ToggleRoute_content__2pxMX {
    display: flex; }
  .ToggleRoute_container__ufgqw input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .ToggleRoute_container__ufgqw .ToggleRoute_checkmark__3pLyg {
    width: 15px;
    height: 15px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 100%; }
  .ToggleRoute_container__ufgqw .ToggleRoute_field__2eALy {
    flex: 1 1;
    margin-left: 0.4rem; }
  .ToggleRoute_container__ufgqw:hover .ToggleRoute_indicator__3mo-h {
    display: inline;
    display: initial; }
  .ToggleRoute_container__ufgqw .ToggleRoute_indicator__3mo-h {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, white 30%, white 100%);
    padding: 3px 3px 3px 8px; }
    .ToggleRoute_container__ufgqw .ToggleRoute_indicator__3mo-h svg {
      width: 15px;
      height: 15px;
      fill: #aaa; }
    .ToggleRoute_container__ufgqw .ToggleRoute_indicator__3mo-h:hover {
      background: white;
      padding: 3px 3px 3px 8px; }
      .ToggleRoute_container__ufgqw .ToggleRoute_indicator__3mo-h:hover svg {
        fill: #000; }

ul.RouteList_inner__2BnV4 {
  list-style: none;
  margin: 0;
  padding: 0 0 0 0.7rem; }
  ul.RouteList_inner__2BnV4 li.RouteList_inner__2BnV4 {
    margin-top: 1px; }
    ul.RouteList_inner__2BnV4 li.RouteList_inner_filter__1H0Ct {
      background-color: rgba(0, 0, 255, 0.2); }
    ul.RouteList_inner__2BnV4 li.RouteList_inner__2BnV4 .RouteList_label__1c7bG {
      display: flex;
      align-items: center;
      padding: 0 0.5em; }
      ul.RouteList_inner__2BnV4 li.RouteList_inner__2BnV4 .RouteList_label__1c7bG .RouteList_colorMark__1ck3a {
        height: 4px;
        width: 1px; }
      ul.RouteList_inner__2BnV4 li.RouteList_inner__2BnV4 .RouteList_label__1c7bG input {
        width: auto;
        margin: 0 0.5rem 0 0; }
      ul.RouteList_inner__2BnV4 li.RouteList_inner__2BnV4 .RouteList_label__1c7bG .RouteList_field__3IiEo {
        flex: 1 1;
        cursor: pointer;
        display: block;
        padding: 0.5em 0;
        color: #000; }

.ToggleButtons_container__3f6uP {
  display: flex;
  padding: 0.4rem 0;
  font-weight: bold;
  color: #555; }
  .ToggleButtons_container__3f6uP .ToggleButtons_toggle__253Hr {
    background: none white;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 4px;
    padding: 0.3rem 0.8rem;
    margin: 0 0.5rem 0 0;
    border: 1px solid rgba(0, 0, 0, 0.15); }
    .ToggleButtons_container__3f6uP .ToggleButtons_toggle_selected__1JfES {
      border: 1px solid #4760ae;
      color: #4760ae; }
    .ToggleButtons_container__3f6uP .ToggleButtons_toggle__253Hr input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0; }

.SideMenu_button__23KII {
  background: none white;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 35px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow: hidden; }
  .SideMenu_button_open__x_gd6 {
    bottom: 0px;
    right: -30px; }
  .SideMenu_button_close__1eo-t {
    bottom: 63px;
    left: 10px;
    z-index: 99;
    color: #ffffff;
    font-weight: bold; }

.SideMenu_container__V2CRf {
  display: none;
  width: 250px;
  font-size: 0.75rem;
  padding: 0.6rem 0.3rem;
  position: absolute;
  top: 8%;
  left: 11px;
  height: 85%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 1; }
  .SideMenu_container__V2CRf .SideMenu_content__Ck7kq {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%; }
  .SideMenu_container__V2CRf ul.SideMenu_events__1pVK5 {
    list-style: none;
    margin: 0;
    padding: 0; }
    .SideMenu_container__V2CRf ul.SideMenu_events__1pVK5 li.SideMenu_event__32W7B {
      margin: 1px 0.5em 0;
      border-bottom: 1px solid #ececec; }
      .SideMenu_container__V2CRf ul.SideMenu_events__1pVK5 li.SideMenu_event_filter__2Nmha {
        background-color: rgba(0, 0, 255, 0.05); }
      .SideMenu_container__V2CRf ul.SideMenu_events__1pVK5 li.SideMenu_event__32W7B .SideMenu_field__3dLoX {
        display: block;
        padding: 1em 0.2em;
        color: #000; }
  @media screen and (min-width: 1024px) {
    .SideMenu_container__V2CRf {
      display: inline;
      display: initial; } }

.Marker_mark__AS8IQ {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transform-origin: bottom;
  transform: translate(-50%, -100%);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }
  .Marker_mark__AS8IQ svg {
    -webkit-filter: drop-shadow(0px 1px 1px #000);
            filter: drop-shadow(0px 1px 1px #000); }
  .Marker_mark__AS8IQ:hover {
    transform: translate(-50%, -100%) scale(1.2); }

.Marker_markerLabel__3ifmL {
  background: #ffffff;
  font-size: 11px;
  position: relative;
  padding: 6px 12px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 1px #00000059;
  margin-bottom: 5px; }
  .Marker_markerLabel__3ifmL:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #c0c0c0d6;
    left: 50%;
    top: 100%;
    transform: translateX(-50%); }
  .Marker_markerLabel__3ifmL div {
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: #1c1b1bd1; }

.LocationMarker_mark__1lHdJ {
  width: 19px;
  height: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transform-origin: bottom;
  transform: translate(-50%, -100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer; }
  .LocationMarker_mark__1lHdJ:hover {
    transform: translate(-50%, -100%) scale(1.2); }
    .LocationMarker_mark__1lHdJ:hover .LocationMarker_markerLabel__KShNw {
      opacity: 1; }

.LocationMarker_markerLabel__KShNw {
  opacity: 0;
  background: #ffffff;
  font-size: 11px;
  width: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px;
  padding-top: 5px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 1px #00000059;
  transition: all 0.2s; }
  .LocationMarker_markerLabel__KShNw:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
    left: 50%;
    top: 100%;
    transform: translateX(-50%); }

.card {
  position: absolute;
  right: 1.8em;
  top: 4.2em;
  width: 500px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  max-width: 85%;
  border-radius: 3%;
  overflow: hidden; }
  .card__header {
    height: auto;
    position: relative;
    padding: 10px 20px 10px;
    display: flex;
    flex-direction: column; }
    .card__header__section {
      padding: 0;
      flex: 1 1; }
      .card__header__section .headsign {
        color: rgba(255, 255, 255, 0.9);
        font-size: 18px;
        font-weight: 500;
        margin: 1% 0; }
      @media screen and (min-width: 480px) {
        .card__header__section {
          padding: 0 20px; } }
    @media screen and (min-width: 480px) {
      .card__header {
        height: 104px;
        flex-direction: row;
        align-items: center; } }
  .card__body {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto; }
  .card__footer {
    padding: 1rem;
    text-align: center;
    opacity: 0.9; }
    .card__footer button {
      color: #ffffff;
      width: 100%;
      font-weight: bold; }

.bus_logo {
  width: 64px;
  height: 64px;
  display: none; }
  @media screen and (min-width: 480px) {
    .bus_logo {
      display: inline;
      display: initial; } }

.row-container {
  display: flex;
  flex-direction: row;
  padding: 1% 2%; }
  .row-container:nth-child(1) {
    padding: 2% 2% 1%; }
  .row-container:last-child {
    padding: 1% 2% 3%; }
  .row-container:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.06); }
  .row-container .label-container {
    width: 35%; }

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer; }

.battery-status {
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  margin: 1% 0; }
  .battery-status .circle {
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #ccc;
    border-radius: 100%;
    margin-right: 5px; }
    .battery-status .circle.red {
      background-color: #eb2b2b; }
    .battery-status .circle.yellow {
      background-color: #dede1f; }
    .battery-status .circle.green {
      background-color: #3ecb3e; }

.battery-level {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  margin: 1% 0; }

.Loading_loading__2jmYJ {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center; }
  .Loading_loading__2jmYJ .Loading_message__32l7I {
    color: #000;
    font-size: 3rem;
    font-weight: bolder;
    -webkit-animation: Loading_pulse__18G-K 1.5s infinite;
            animation: Loading_pulse__18G-K 1.5s infinite; }

@-webkit-keyframes Loading_pulse__18G-K {
  0% {
    opacity: 0.8; }
  50% {
    opacity: 0.4; }
  100% {
    opacity: 0.8; } }

@keyframes Loading_pulse__18G-K {
  0% {
    opacity: 0.8; }
  50% {
    opacity: 0.4; }
  100% {
    opacity: 0.8; } }

.Tracker_container__kmZlf {
  height: 100%;
  position: relative; }

.Row_row__2O8n9 {
  padding: 1em 0;
  position: relative; }
  @media screen and (min-width: 480px) {
    .Row_row__2O8n9 {
      padding: 1em 0.3em; } }
  .Row_row__2O8n9 .Row_container__3wuGK {
    display: flex; }
  .Row_row__2O8n9 .Row_status__Ra1UF {
    font-size: 0.7rem;
    text-align: center;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Row_row__2O8n9 .Row_status__Ra1UF .Row_circle__1vnQM {
      display: inline-block;
      height: 15px;
      width: 15px;
      background-color: #ccc;
      border-radius: 100%; }
      .Row_row__2O8n9 .Row_status__Ra1UF .Row_circle__1vnQM.Row_red__59hiL {
        background-color: #eb2b2b; }
      .Row_row__2O8n9 .Row_status__Ra1UF .Row_circle__1vnQM.Row_yellow__2-fmP {
        background-color: #dede1f; }
      .Row_row__2O8n9 .Row_status__Ra1UF .Row_circle__1vnQM.Row_green__39r2f {
        background-color: #3ecb3e; }
  .Row_row__2O8n9 .Row_battery__tVRX8 {
    font-size: 0.7rem;
    padding: 0 3em 0 1em; }
  .Row_row__2O8n9 .Row_information__1y6Zg {
    flex: 1 1; }
    .Row_row__2O8n9 .Row_information__1y6Zg .Row_head__31cmO {
      padding: 0 0 0.7rem; }
      .Row_row__2O8n9 .Row_information__1y6Zg .Row_head__31cmO h2 {
        margin: 0; }
      @media screen and (min-width: 480px) {
        .Row_row__2O8n9 .Row_information__1y6Zg .Row_head__31cmO {
          padding: 0 10em 0.7rem 0; } }
    .Row_row__2O8n9 .Row_information__1y6Zg .Row_content__mNWU_ {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px; }
      .Row_row__2O8n9 .Row_information__1y6Zg .Row_content__mNWU_ p {
        flex: 1 1 170px;
        margin: 0; }
  .Row_row__2O8n9 .Row_call__2DHyU {
    text-align: center;
    margin: 1em 0 0;
    padding: 0.7em 0;
    background-color: #12a1e1;
    color: white;
    font-weight: bold; }
    .Row_row__2O8n9 .Row_call__2DHyU a {
      color: inherit; }
    @media screen and (min-width: 480px) {
      .Row_row__2O8n9 .Row_call__2DHyU {
        position: absolute;
        right: 0.3em;
        top: 1em;
        margin: 0;
        padding: 0;
        color: #000;
        color: initial;
        font-weight: normal;
        background: none; } }

.Management_management__abFny {
  max-width: 720px;
  margin: 0 auto;
  padding: 0;
  background-color: white; }
  .Management_management__abFny .Management_filterBox__2YKsU {
    display: flex; }
    .Management_management__abFny .Management_filterBox__2YKsU input {
      font-size: 1.3em;
      padding: 0.6rem 1.3rem;
      height: 60px;
      border: none;
      border-bottom: 2px solid #ccc;
      width: 80%; }
    .Management_management__abFny .Management_filterBox__2YKsU select {
      font-size: 1.3em;
      padding: 0.6rem 1.3rem;
      height: 60px;
      border: none;
      border-bottom: 2px solid #ccc;
      border-left: 1px solid #e3e3e3;
      width: 50%;
      background-color: white; }
      @media screen and (min-width: 480px) {
        .Management_management__abFny .Management_filterBox__2YKsU select {
          width: 25%; } }
  .Management_management__abFny .Management_locations__10KGj {
    list-style: none;
    margin: 0;
    padding: 0 1em; }
    .Management_management__abFny .Management_locations__10KGj li {
      border-top: 1px solid #cccccc; }
      .Management_management__abFny .Management_locations__10KGj li:first-child {
        border-top: none; }

*,
:after,
:before {
  box-sizing: border-box; }

html,
body,
#root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px; }

#root {
  display: flex;
  flex-direction: column; }

#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

a {
  color: #47a0db;
  text-decoration: none; }

p.error {
  color: #a94442;
  text-align: left;
  text-align: initial; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.rdtPicker {
  right: 0; }

