.row {
  padding: 1em 0;
  position: relative;

  @media screen and (min-width: 480px) {
    padding: 1em 0.3em;
  }

  .container {
    display: flex;
  }

  .status {
    font-size: 0.7rem;
    text-align: center;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
      display: inline-block;
      height: 15px;
      width: 15px;
      background-color: #ccc;
      border-radius: 100%;

      &.red {
        background-color: #eb2b2b;
      }

      &.yellow {
        background-color: #dede1f;
      }

      &.green {
        background-color: #3ecb3e;
      }
    }
  }

  .battery {
    font-size: 0.7rem;
    padding: 0 3em 0 1em;
  }

  .information {
    flex: 1;

    .head {
      padding: 0 0 0.7rem;
      h2 {
        margin: 0;
      }
      @media screen and (min-width: 480px) {
        padding: 0 10em 0.7rem 0;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      p {
        flex: 1 1 170px;
        margin: 0;
      }
    }
  }

  .call {
    text-align: center;
    margin: 1em 0 0;
    padding: 0.7em 0;
    background-color: #12a1e1;
    color: white;
    font-weight: bold;

    a {
      color: inherit;
    }

    @media screen and (min-width: 480px) {
      position: absolute;
      right: 0.3em;
      top: 1em;
      margin: 0;
      padding: 0;
      color: initial;
      font-weight: normal;
      background: none;
    }
  }
}
