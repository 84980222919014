.button {
  background: none rgb(255, 255, 255);
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  height: 35px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow: hidden;

  &_open {
    bottom: 0px;
    right: -30px;
  }

  &_close {
    bottom: 63px;
    left: 10px;
    z-index: 99;
    color: #ffffff;
    font-weight: bold;
  }
}

.container {
  display: none;
  width: 250px;
  font-size: 0.75rem;
  padding: 0.6rem 0.3rem;
  position: absolute;
  top: 8%;
  left: 11px;
  height: 85%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 1;

  .content {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
  }

  ul.events {
    list-style: none;
    margin: 0;
    padding: 0;
    li.event {
      margin: 1px 0.5em 0;
      border-bottom: 1px solid #ececec;

      &_filter {
        background-color: rgba(blue, 0.05);
      }

      .field {
        display: block;
        padding: 1em 0.2em;
        color: #000;

        // &.activeEvent,
        // &:hover {
        //   background-color: #ececec;
        // }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    display: initial;
  }
}
