ul.inner {
  list-style: none;
  padding: 0 0 0 0.7rem;
  margin: 0 0 0 0.45rem;
  border-left: 2px solid;

  li.inner {
    margin-top: 1px;

    .field {
      flex: 1;
      display: block;
      position: relative;
      padding: 0.5em;
      color: #000;

      &:hover {
        .indicator {
          display: initial;
        }
      }
      &_filter {
        background-color: rgba(blue, 0.2);
      }
    }

    .indicator {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 1) 100%
      );
      padding: 3px 3px 3px 8px;

      svg {
        width: 15px;
        height: 15px;
        fill: #aaa;
      }

      &:hover {
        background: white;
        padding: 3px 3px 3px 8px;

        svg {
          fill: #000;
        }
      }
    }
  }
}

.visible {
  opacity: 0.5;
}
