.mark {
  user-select: none;
  transform-origin: bottom;
  transform: translate(-50%, -100%);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  svg {
    filter: drop-shadow(0px 1px 1px #000);
  }
  &:hover {
    transform: translate(-50%, -100%) scale(1.2);
  }
}

.markerLabel {
  background: #ffffff;
  font-size: 11px;
  position: relative;
  padding: 6px 12px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 1px #00000059;
  margin-bottom: 5px;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #c0c0c0d6;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
  div {
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: #1c1b1bd1;
  }
}
