.mark {
  width: 19px;
  height: 30px;
  user-select: none;
  transform-origin: bottom;
  transform: translate(-50%, -100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    .markerLabel {
      opacity: 1;
    }
    transform: translate(-50%, -100%) scale(1.2);
  }
}

.markerLabel {
  opacity: 0;
  background: #ffffff;
  font-size: 11px;
  width: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px;
  padding-top: 5px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 1px #00000059;
  transition: all 0.2s;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
}
