.container {
  display: flex;
  padding: 0.4rem 0;
  font-weight: bold;
  color: #555;

  .toggle {
    background: none rgb(255, 255, 255);
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    padding: 0.3rem 0.8rem;
    margin: 0 0.5rem 0 0;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &_selected {
      border: 1px solid #4760ae;
      color: #4760ae;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
    }
  }
}
