.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    position: relative;
    flex: 1;
  }
}
