.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    color: #000;
    font-size: 3rem;
    font-weight: bolder;
    animation: pulse 1.5s infinite;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
