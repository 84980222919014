.container {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0.2rem 0;

  &.visible {
    opacity: 0.5;
  }

  .content {
    display: flex;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    width: 15px;
    height: 15px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 100%;
  }

  .field {
    flex: 1;
    margin-left: 0.4rem;
  }

  &:hover {
    // .checkmark {
    //   filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.75));
    // }
    .indicator {
      display: initial;
    }
    // .field {
    //   background-color: #f7f7f7;
    // }
  }

  .indicator {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    padding: 3px 3px 3px 8px;

    svg {
      width: 15px;
      height: 15px;
      fill: #aaa;
    }

    &:hover {
      background: white;
      padding: 3px 3px 3px 8px;

      svg {
        fill: #000;
      }
    }
  }
}
